import { Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { getStatusFor } from "../../helper/HelperFunctions";
import {
  deleteService,
  getJsonResult,
  post,
} from "../../services/FetchServices";
import OrderRelease from "../../types/OrderRelease";
import SearchOrderRelease from "../../types/SearchOrderRelease";
import { DropDown } from "../dropDown/DropDown";
import Report from "../Report";
import {
  getOrderReleaseReportData,
  getOrderReleaseReportFixedHeaders,
  getReportFileName,
  subtractOneDayFrom,
} from "./OrderReleaseReportHelper";

interface FindOrderReleaseProps {
  searchOrderRelease: SearchOrderRelease;
}

const SearchOrderReleasePage: React.FC = () => {
  const location = useLocation();
  let searchOrderRelease = (location?.state as FindOrderReleaseProps)
    ?.searchOrderRelease;
  const [orderReleaseId, setOrderReleaseId] = useState("");
  const [orderReleaseIdToDelete, setOrderReleaseIdToDelete] = useState("");
  const [shipmentId, setShipmentId] = useState("");
  const [freightTerms, setFreightTerms] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [earlyPickupOnOrAfter, setEarlyPickupOnOrAfter] = useState<Date | null>(
    null
  );
  const [earlyPickupOnOrBefore, setEarlyPickupOnOrBefore] =
    useState<Date | null>(null);
  const [latePickupOnOrAfter, setLatePickupOnOrAfter] = useState<Date | null>(
    null
  );
  const [latePickupOnOrBefore, setLatePickupOnOrBefore] = useState<Date | null>(
    null
  );
  const [earlyDeliveryOnOrAfter, setEarlyDeliveryOnOrAfter] =
    useState<Date | null>(null);
  const [earlyDeliveryOnOrBefore, setEarlyDeliveryOnOrBefore] =
    useState<Date | null>(null);
  const [lateDeliveryOnOrAfter, setLateDeliveryOnOrAfter] =
    useState<Date | null>(null);
  const [lateDeliveryOnOrBefore, setLateDeliveryOnOrBefore] =
    useState<Date | null>(null);
  const [poCreationOnOrAfter, setPoCreationOnOrAfter] = useState<Date | null>(
    null
  );
  const [poCreationOnOrBefore, setPoCreationOnOrBefore] = useState<Date | null>(
    null
  );
  const [destinationDc, setDestinationDc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderReleases, setOrderReleases] = useState<OrderRelease[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openAlert, setAlertOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [cityValues, setCityValues] = useState([]);
  const convertDate = (dateTime: Date) => {
    let date = ("0" + dateTime.getDate()).slice(-2);
    let month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
    let year = dateTime.getFullYear();
    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    let seconds = dateTime.getSeconds();
    let covertedDate =
      year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
    return covertedDate;
  };
  const lastYearDate = () => {
    let dateTime = new Date();
    let date = ("0" + dateTime.getDate()).slice(-2);
    if(dateTime.getMonth() + 1 === 2 && ("0" + dateTime.getDate()).slice(-2) === "29"){
      date = ("0" + (dateTime.getDate() -1)).slice(-2);
    }
    let month = ("0" + (dateTime.getMonth() + 1)).slice(-2);
    let year = dateTime.getFullYear() - 1; //subtract 1 year from date today for now
    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    let seconds = dateTime.getSeconds();
    let lastYearDate =
      year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
    return lastYearDate;
  };

  let deleteStatusMap = new Map([
    ["PLANNING_NEW", true],
    ["PLANNING_UNSCHEDULED", true],
  ]);

  const canDelete = ( orderRelease:OrderRelease ):boolean => {
    return (orderRelease.cancelledStatus !== 'CANCELLED_CANCELLED') && (deleteStatusMap.get(orderRelease.planningStatus)??false)
  }

  var authHeader = localStorage.getItem("authHeader");
  var username = localStorage.getItem("userName");

  const findOrderRelease = () => {
    setIsLoading(true);

    var findOrderReleaseUrl =
      `${process.env.REACT_APP_BACKEND_URL}/OrderRelease/find?authHeader=` +
      authHeader +
      "&vendorId=" +
      localStorage.getItem("vendorId");
    post(findOrderReleaseUrl, orderReleaseJson)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((data) => {
        if (data == null || data.length === 0) {
          setAlertOpen(true);
          setDialogContent("No results returned from the search criteria.");
          setDialogTitle("Warning!");
        }
        setOrderReleases(data);
        setIsLoading(false);
        fixOrderReleaseTableHeaderWidth();
      })
      .catch((error) => {
        error.text().then((errMessage: any) => {
          if (errMessage.indexOf("500") > -1) {
            setAlertOpen(true);
            setDialogContent("Search timed out, please run search again.");
            setDialogTitle("Error!");
            setIsLoading(false);
          }
        });
      });
  };
  let orderReleaseJson = {
    orderReleaseGid: orderReleaseId,
    shipmentId: shipmentId,
    freightTerms: freightTerms,
    earlyPickupDate:
      earlyPickupOnOrAfter !== null ? convertDate(earlyPickupOnOrAfter) : null,
    earlyPickupDateBefore:
      earlyPickupOnOrBefore !== null
        ? convertDate(earlyPickupOnOrBefore)
        : null,
    latePickupDate:
      latePickupOnOrAfter !== null ? convertDate(latePickupOnOrAfter) : null,
    latePickupDateBefore:
      latePickupOnOrBefore !== null ? convertDate(latePickupOnOrBefore) : null,
    poCreationDate:
      poCreationOnOrAfter !== null
        ? convertDate(poCreationOnOrAfter)
        : lastYearDate(),
    poCreationDateBefore:
      poCreationOnOrBefore !== null ? convertDate(poCreationOnOrBefore) : null,
    earlyDeliveryDate:
      earlyDeliveryOnOrAfter !== null
        ? convertDate(earlyDeliveryOnOrAfter)
        : null,
    earlyDeliveryDateBefore:
      earlyDeliveryOnOrBefore !== null
        ? convertDate(earlyDeliveryOnOrBefore)
        : null,
    lateDeliveryDate:
      lateDeliveryOnOrAfter !== null
        ? convertDate(lateDeliveryOnOrAfter)
        : null,
    lateDeliveryDateBefore:
      lateDeliveryOnOrBefore !== null
        ? convertDate(lateDeliveryOnOrBefore)
        : null,
    shipTo: destinationDc.split(" ")[0],
    appointmentId: appointmentId,
  };

  var getDcCityValuesUrl =
    `${process.env.REACT_APP_BACKEND_URL}/po/dcCity?authHeader=` + authHeader;

  useEffect(() => {
    getJsonResult(getDcCityValuesUrl)
      .then((response) => response.json())
      .then((data) => setCityValues(data));

    if (searchOrderRelease !== undefined) {
      orderReleaseJson = {
        orderReleaseGid: searchOrderRelease.orderReleaseGid,
        shipmentId: searchOrderRelease.shipmentId,
        freightTerms: searchOrderRelease.freightTerms,
        earlyPickupDate:
          searchOrderRelease.earlyPickupDate !== null
            ? convertDate(searchOrderRelease.earlyPickupDate)
            : null,
        earlyPickupDateBefore:
          searchOrderRelease.earlyPickupDateBefore !== null
            ? convertDate(searchOrderRelease.earlyPickupDateBefore)
            : null,
        latePickupDate:
          searchOrderRelease.latePickupDate !== null
            ? convertDate(searchOrderRelease.latePickupDate)
            : null,
        latePickupDateBefore:
          searchOrderRelease.latePickupDateBefore !== null
            ? convertDate(searchOrderRelease.latePickupDateBefore)
            : null,
        poCreationDate:
          searchOrderRelease.poCreationDate !== null
            ? convertDate(searchOrderRelease.poCreationDate)
            : lastYearDate(),
        poCreationDateBefore:
          searchOrderRelease.poCreationDateBefore !== null
            ? convertDate(searchOrderRelease.poCreationDateBefore)
            : null,
        earlyDeliveryDate:
          searchOrderRelease.earlyDeliveryDate !== null
            ? convertDate(searchOrderRelease.earlyDeliveryDate)
            : null,
        earlyDeliveryDateBefore:
          searchOrderRelease.earlyDeliveryDateBefore !== null
            ? convertDate(searchOrderRelease.earlyDeliveryDateBefore)
            : null,
        lateDeliveryDate:
          searchOrderRelease.lateDeliveryDate !== null
            ? convertDate(searchOrderRelease.lateDeliveryDate)
            : null,
        lateDeliveryDateBefore:
          searchOrderRelease.lateDeliveryDateBefore !== null
            ? convertDate(searchOrderRelease.lateDeliveryDateBefore)
            : null,
        shipTo: searchOrderRelease.shipTo.split(" ")[0],
        appointmentId: searchOrderRelease.appointmentId,
      };

      setOrderReleaseId(searchOrderRelease.orderReleaseGid);
      setAppointmentId(searchOrderRelease.appointmentId);
      setFreightTerms(searchOrderRelease.freightTerms);
      setDestinationDc(searchOrderRelease.shipTo);
      setEarlyDeliveryOnOrAfter(searchOrderRelease.earlyDeliveryDate);
      setEarlyDeliveryOnOrBefore(searchOrderRelease.earlyDeliveryDateBefore);
      setEarlyPickupOnOrAfter(searchOrderRelease.earlyPickupDate);
      setEarlyPickupOnOrBefore(searchOrderRelease.earlyPickupDateBefore);
      setLateDeliveryOnOrAfter(searchOrderRelease.lateDeliveryDate);
      setLateDeliveryOnOrBefore(searchOrderRelease.lateDeliveryDateBefore);
      setLatePickupOnOrAfter(searchOrderRelease.latePickupDate);
      setLatePickupOnOrBefore(searchOrderRelease.latePickupDateBefore);
      setPoCreationOnOrAfter(searchOrderRelease.poCreationDate);
      setPoCreationOnOrBefore(searchOrderRelease.poCreationDateBefore);
      setShipmentId(searchOrderRelease.shipmentId);

      findOrderRelease();
    }
  }, []);

  const history = useHistory();
  const routeToOrderRelease = (orderReleaseGid: string) => {
    var orderReleaseUrl =
      `${process.env.REACT_APP_BACKEND_URL}/OrderRelease/getOrderRelease?authHeader=` +
      authHeader +
      "&orderReleaseGid=" +
      orderReleaseGid;
    searchOrderRelease = new SearchOrderRelease(
      orderReleaseId,
      earlyPickupOnOrAfter,
      earlyPickupOnOrBefore,
      latePickupOnOrAfter,
      latePickupOnOrBefore,
      destinationDc,
      freightTerms,
      shipmentId,
      poCreationOnOrAfter,
      poCreationOnOrBefore,
      earlyDeliveryOnOrAfter,
      earlyDeliveryOnOrBefore,
      lateDeliveryOnOrAfter,
      lateDeliveryOnOrBefore,
      appointmentId
    );

    getJsonResult(orderReleaseUrl)
      .then((response) => response.json())
      .then((data: OrderRelease) => {
        history.push({
          pathname: "/orderRelease",
          state: { orderRelease: data, searchOrderRelease: searchOrderRelease },
        });
      });
  };

  const deleteOrder = () => {
    closeDialog();
    var deleteLocationUrl =
      `${process.env.REACT_APP_BACKEND_URL}/OrderRelease/deleteOrder?authHeader=` +
      authHeader +
      "&orderReleaseGid=" +
      orderReleaseIdToDelete;
    deleteService(deleteLocationUrl)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.text();
        } else {
          throw response;
        }
      })
      .then((data) => {
        setDialogContent("Order successfully deleted");
        setDialogTitle("Success!");
        setAlertOpen(true);
        findOrderRelease();
        setIsLoading(false);
      })
      .catch((error) => {
        error.text().then((errMessage: any) => {
          setDialogContent("Error when deleting order:\n" + errMessage);
          setDialogTitle("Error!");
          setAlertOpen(true);
        });
      });
  };

  const handleOrderReleaseId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderReleaseId(e.target.value);
  };

  const handleShipmentId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShipmentId(e.target.value);
  };

  const handleFreightTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFreightTerms(e.target.value);
  };

  const handleAppointmentId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppointmentId(e.target.value);
  };

  const handleDestinationDc = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDestinationDc(e.target.value);
  };

  const openDialog = (orderReleaseId: string) => {
    setOrderReleaseIdToDelete(orderReleaseId);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const closeAlertDialog = () => {
    setAlertOpen(false);
  };

  const onKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      findOrderRelease();
      event.preventDefault();
    }
  };

  const fixOrderReleaseTableHeaderWidth = () => {
    var $table = $("#orderReleaseTable");
    var $bodyCells = $table.find("tbody tr:first").children();

    var colWidth = $bodyCells
      .map(function () {
        return $(this).width();
      })
      .get();

    $table
      .find("thead tr")
      .children()
      .each(function (i, v) {
        $(v).width(colWidth[i]);
      });
  };

  return (
    <section
      className="sectionHeadingPadding"
      onKeyDown={onKeyDown}
      tabIndex={-1}
      style={{ outline: "none" }}
    >
      <div id="searchOrderReleasePage" className="container">
        <div id="findOrderReleasePage" className="intro">
          <div id="orderReleaseFinder" className="section-heading mb-4">
            <span
              className="section-heading-lower"
              style={{
                fontSize: "1.9rem",
                color: "#6b3a06",
                textAlign: "center",
              }}
            >
              ORDER RELEASE (OR) FINDER
            </span>
          </div>

          <div className="row mb-5">
            <div className="col-lg-12 mx-auto">
              <div
                className="bg-grey p-5 rounded"
                style={{ boxShadow: " 0px 5px 10px #91d475" }}
              >
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2">
                      <TextField
                        id="orderReleaseId"
                        label="Order Release ID"
                        variant="outlined"
                        className="form-control"
                        value={orderReleaseId}
                        onChange={handleOrderReleaseId}
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <TextField
                        id="shipmentId"
                        label="Shipment ID"
                        variant="outlined"
                        className="form-control"
                        value={shipmentId}
                        onChange={handleShipmentId}
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <DropDown
                        codes={["PREPAID", "COLLECT"]}
                        elementId="freightTerms"
                        value={freightTerms}
                        onChange={handleFreightTerms}
                        label="Freight Terms"
                        defaultOption="None"
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <TextField
                        id="appointmentId"
                        label="Delivery Appointment ID"
                        variant="outlined"
                        className="form-control"
                        value={appointmentId}
                        onChange={handleAppointmentId}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Early Product Ready on or after"
                          views={["year", "month", "day"]}
                          value={earlyPickupOnOrAfter}
                          onChange={(newValue : any) => {
                            setEarlyPickupOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="earlyPickupOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Early Product Ready on or before"
                          views={["year", "month", "day"]}
                          value={earlyPickupOnOrBefore}
                          onChange={(newValue : any) => {
                            setEarlyPickupOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="earlyPickupOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Late Product Ready on or after"
                          views={["year", "month", "day"]}
                          value={latePickupOnOrAfter}
                          onChange={(newValue : any) => {
                            setLatePickupOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="latePickupOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Late Product Ready on or before"
                          views={["year", "month", "day"]}
                          value={latePickupOnOrBefore}
                          onChange={(newValue : any) => {
                            setLatePickupOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="latePickupOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Early Delivery On or After"
                          views={["year", "month", "day"]}
                          value={earlyDeliveryOnOrAfter}
                          onChange={(newValue : any) => {
                            setEarlyDeliveryOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="earlyDeliveryOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Early Delivery On or Before"
                          views={["year", "month", "day"]}
                          value={earlyDeliveryOnOrBefore}
                          onChange={(newValue : any) => {
                            setEarlyDeliveryOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="earlyDeliveryOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Late Delivery On or After"
                          views={["year", "month", "day"]}
                          value={lateDeliveryOnOrAfter}
                          onChange={(newValue : any) => {
                            setLateDeliveryOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="lateDeliveryOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Late Delivery On or Before"
                          views={["year", "month", "day"]}
                          value={lateDeliveryOnOrBefore}
                          onChange={(newValue : any) => {
                            setLateDeliveryOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="lateDeliveryOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="OR Creation On or After"
                          views={["year", "month", "day"]}
                          value={poCreationOnOrAfter}
                          onChange={(newValue : any) => {
                            setPoCreationOnOrAfter(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="poCreationOnOrAfter"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="OR Creation On or Before"
                          views={["year", "month", "day"]}
                          value={poCreationOnOrBefore}
                          onChange={(newValue : any) => {
                            setPoCreationOnOrBefore(newValue);
                          }}
                          renderInput={(params : any) => (
                            <TextField
                              {...params}
                              id="poCreationOnOrBefore"
                              className="form-control"
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2">
                      <DropDown
                        codes={cityValues}
                        elementId="destinationDc"
                        value={destinationDc}
                        onChange={handleDestinationDc}
                        label="DC# CITY STATE"
                        defaultOption="ALL"
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group py-2"></div>
                  </div>
                </div>
                <div className="row">
                  <div style={{ textAlign: "center" }}>
                    <span>
                      <button
                        type="button"
                        className="btn btn-success"
                        id="searchOrderReleaseButton"
                        onClick={findOrderRelease}
                        style={{
                          width: 100,
                          marginBottom: "10px",
                        }}
                      >
                        Find
                      </button>
                    </span>
                    &nbsp;
                    {orderReleases.length > 0 ? (
                      <span>
                        <Report
                          id="orderReleaseReport"
                          headers={getOrderReleaseReportFixedHeaders()}
                          data={getOrderReleaseReportData(orderReleases)}
                          filename={getReportFileName("Order_Release_Report")}
                          title="Order Release"
                        >
                          <button
                            id="exportOrderReleaseButton"
                            type="button"
                            className="btn btn-success"
                            style={{
                              width: 100,
                              marginBottom: "10px",
                            }}
                          >
                            Export
                          </button>
                        </Report>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="orderReleaseTableDiv" className="overflowX">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border text-primary"
                id="orderReleaseSpinner"
                role="status"
              ></div>
            </div>
          ) : (
            <table
              className="table"
              id="orderReleaseTable"
              style={{ margin: "0 auto", borderCollapse: "collapse" }}
            >
              <thead style={{ display: "block", width: "calc(100% - 0.9em)" }}>
                <tr>
                  <th>Order Release ID</th>
                  <th>Status</th>
                  <th>Early Product Ready</th>
                  <th>Late Product Ready</th>
                  <th>Ship From</th>
                  <th>Ship To</th>
                  <th>Freight Terms</th>
                  <th>Shipment ID</th>
                  <th>SCAC</th>
                  <th>Delivery Appointment ID</th>
                  <th>Vendor Pickup Number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                style={{
                  display: "block",
                  overflowY: "scroll",
                  maxHeight: "650px",
                }}
              >
                {orderReleases.map((orderRelease: OrderRelease) => (
                  <tr>
                    <td className="nowrap">
                      <Link
                        onClick={() =>
                          routeToOrderRelease(orderRelease.orderReleaseGid)
                        }
                      >
                        {orderRelease.orderReleaseGid.startsWith("FD.")
                          ? orderRelease.orderReleaseGid.slice(3)
                          : orderRelease.orderReleaseGid}
                      </Link>
                    </td>
                    <td
                      title={
                        canDelete(orderRelease)
                          ? "Eligible for deletion"
                          : "May not delete"
                      }
                    >
                      {orderRelease.freightTerms === "FD.PREPAID"
                        ? "PREPAID"
                        : getStatusFor(orderRelease)}
                    </td>
                    <td>{orderRelease.earlyPickupDate.split(" ")[0]}</td>
                    <td>
                      {
                        subtractOneDayFrom(orderRelease.latePickupDate)?.split(
                          "T"
                        )[0]
                      }
                    </td>
                    <td>{orderRelease.shipFrom}</td>
                    <td>{orderRelease.shipTo.split(".")[1]}</td>
                    <td>{orderRelease.freightTerms.split(".")[1]}</td>
                    <td>
                      {orderRelease.shipmentId
                        ? orderRelease.shipmentId
                        : "No data to display"}
                    </td>
                    <td>
                      {orderRelease.scac
                        ? orderRelease.scac.split(".")[1]
                        : "No data to display"}
                    </td>
                    <td>
                      {orderRelease.appointmentId
                        ? orderRelease.appointmentId
                        : "No data to display"}
                    </td>
                    <td>
                    {orderRelease.vendorPickupNumber
                        ? orderRelease.vendorPickupNumber
                        : "No data to display"}
                    </td>
                    <td>
                      { canDelete(orderRelease) && (
                        <button
                          id="deleteOrderButton"
                          name={
                            orderRelease.orderReleaseGid +
                            "deleteOrderButtonName"
                          }
                          className="btn btn-danger"
                          onClick={() =>
                            openDialog(orderRelease.orderReleaseGid)
                          }
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div id="deleteDialog">
        <Dialog open={open} onClose={closeDialog}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id="searchLocationButton"
              onClick={closeDialog}
              color="primary"
              autoFocus
            >
              No
            </Button>
            <Button
              id="searchLocationButton"
              onClick={deleteOrder}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div id="alertDialog">
        <Dialog open={openAlert}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogContent}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id="Button"
              onClick={closeAlertDialog}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </section>
  );
};

export default SearchOrderReleasePage;
