import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomePageLogo from "../../images/HomePage.jpeg";
import planningOne from "../../images/image001.jpeg";
import planningTwo from "../../images/image002.jpeg";
import planningThree from "../../images/image003.jpeg";
import planningFour from "../../images/image004.jpeg";
import ReleaseNotes from "../../images/release_notes.png";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { isValidEmail } from "../../helper/ContactValidationUtilities";
import { getJsonResult, post, put } from "../../services/FetchServices";
import CircularProgress from "@mui/material/CircularProgress";

const NavBarHomePage: React.FC = () => {
  const [openAlert, setAlertOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLasttName, setIsValidLastName] = useState(true);
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);
  const [contactInfo, setContactInfo] = useState(Object);
  const [contactInfoAvailable, setContactInfoAvailable] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [loading, setLoading] = useState(true);

  const closeAlertDialog = () => {
    setAlertOpen(false);
  };

  const handleSubmit = () => {
    const isValidUser = handleFirstName() && handleLastName();
    if (isValidUser && isValidPhoneNumber && isValidEmailAddress) {
      var authHeader = localStorage.getItem("authHeader");
      var username = localStorage.getItem("userName");

      if (contactInfoAvailable) {
        var url = `${process.env.REACT_APP_BACKEND_URL}/login/updateContact?authHeader=` + authHeader + `&id=` + username;
        var updatedContactInfo = {
          Contact: {
            ...contactInfo["Contact"],
            "firstName": firstName,
            "lastName": lastName,
            "emailAddress": email,
            "phone1": phone
          }
        };
        setContactInfo(updatedContactInfo);
        post(url, updatedContactInfo)
          .then((response) => response.json())
          .then((data: any) => {
            console.log("response from the updateContact", data);
          });
      }
      else {
        var url = `${process.env.REACT_APP_BACKEND_URL}/login/createContact?authHeader=` + authHeader;
        var createContactInfo = {
          Contact: {
            "contactGid": username,
            "contactXid": username?.split('.')[1],
            "firstName": firstName,
            "lastName": lastName,
            "emailAddress": email,
            "phone1": phone,
            "isPrimaryContact": true,
            "notes": null,
            "routeThroughLocation": false,
            "contactType": "PERSON",
            "isBroadcast": true,
            "isEmailVirusProtected": false,
            "consolidatedNotifyOnly": false,
            "isNotificationOn": true,
            "isFromAddress": false,
            "sendAsMessage": false,
            "domainName": username?.split('.')[0],
            "attributeDate1": null,
            "attributeDate2": null,
            "attributeDate3": null,
            "attributeDate4": null,
            "attributeDate5": null,
            "attributeDate6": null,
            "attributeDate7": null,
            "attributeDate8": null,
            "attributeDate9": null,
            "attributeDate10": null,
            "nameAddressUpdateDate": null,
            "updateDate": {
              "date": new Date().toISOString()
            },
            "contactStatus": [
              {
                "transactionCode": "IU",
                "contactStatusBeanData": {
                  "domainName": username?.split('.')[0],
                  "contactGid": username,
                  "statusTypeGid": "FD.RPLS",
                  "statusValueGid": "FD.RPLS_NOT STARTED"
                }
              }
            ],
            "contactComMethod": [
              {
                "transactionCode": "IU",
                "contactComMethodBeanData": {
                  "contactGid": username,
                  "comMethodRank": 1,
                  "comMethodGid": "EMAIL",
                  "expectedResponseTime": null,
                  "domainName": username?.split('.')[0]
                }
              }
            ]
          }
        };
        setContactInfo(createContactInfo);
        put(url, createContactInfo)
          .then((response) => response.json())
          .then((data: any) => {
            console.log("response from the createContact", data);
          });
      }
      closeAlertDialog();
    }
  };

  const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = e.target.value.replace(/[^0-9]/g, "");
    setPhone(newPhoneNumber);
    if (
      newPhoneNumber.trim().length === 0 ||
      newPhoneNumber.trim().length === 10
    ) {
      setIsValidPhoneNumber(true);
    } else {
      setIsValidPhoneNumber(false);
    }
  };

  const handleFirstName = () => {
    if (firstName.length == 0 || firstName.length > 80) {
      setIsValidFirstName(false);
      return false;
    } else {
      setIsValidFirstName(true);
      return true;
    }
  };

  const handleLastName = () => {
    if (lastName.length == 0 || lastName.length > 80) {
      setIsValidLastName(false);
      return false;
    } else {
      setIsValidLastName(true);
      return true;
    }
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail.trim());
    if (newEmail.trim().length > 0 && !isValidEmail(newEmail)) {
      setIsValidEmailAddress(false);
    } else {
      setIsValidEmailAddress(true);
    }
  };

  useEffect(() => {
    if (refresh) {
      const authHeader = localStorage.getItem("authHeader");
      const username = localStorage.getItem("userName");
  
      if (authHeader && username) {  // Ensure both exist
        const url = `${process.env.REACT_APP_BACKEND_URL}/login/getContact?authHeader=${authHeader}&id=${username}`;
  
        getJsonResult(url)
          .then((response) => response.json())
          .then((data: any) => {
            if (data.Contact) {
              setContactInfo(data);
              setContactInfoAvailable(true);
              const { firstName, lastName, emailAddress, phone1 } = data.Contact;
              setFirstName(firstName);
              setLastName(lastName);
              setEmail(emailAddress);
              setPhone(phone1);
    
              if (!firstName || !lastName || !emailAddress || !phone1) {
                setAlertOpen(true);
              }
            }
            else {
              setAlertOpen(true);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching contact info:", error);
            setLoading(false);
          });
      } else {
        console.warn("Missing authHeader or username in localStorage");
      }
  
      setRefresh(false);
    }
  }, [refresh]);
  

  return (
    <>
      <body>
        <div id="navBarHomePage" className="pageContent-style">
          <section className="page-section clearfix">
            <div className="container">
              <div className="intro">
                <img
                  className="intro-img img-fluid mb-3 mb-lg-0 rounded"
                  src={HomePageLogo}
                  alt="..."
                />
                <div className="intro-text left-0 text-center bg-faded p-5 rounded">
                  <h2 className="section-heading mb-4">
                    <span className="section-heading-lower">
                      WELCOME TO CVP
                    </span>
                  </h2>
                  <p className="mb-3">
                    Domestic Business Partners, welcome to our Custom Vendor
                    Portal (CVP), a critical component to the Purchase Order
                    fulfillment process for Dollar Tree & Family Dollar. Your
                    timely, accurate and complete entry of the required
                    information as you create order releases is the foundation
                    of an efficient supply chain process to get your products to
                    market. The CVP toolset, supporting guides and tutorials
                    will enable you to successfully complete all needed
                    information. Your compliance is essential and appreciated.
                  </p>
                  <div className="intro-button mx-auto">
                    <a className="btn btn-primary btn-xl" href="#">
                      <Link
                        to="/tour"
                        id="tour"
                        className="nav-link"
                        role="button"
                      >
                        Take a tour!
                      </Link>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="page-section cta">
            <div className="container">
              <div className="row">
                <div className="top-content">
                  <div className="container-fluid">
                    <div
                      id="demo"
                      className="carousel slide carousel-fade"
                      data-ride="carousel"
                    >
                      <div className="container carousel-inner no-padding">
                        <Carousel nextLabel="" prevLabel="">
                          <Carousel.Item className="carousel-item">
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningOne} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningTwo} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningThree} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningFour} alt="..." />
                            </div>
                          </Carousel.Item>
                          <Carousel.Item className="carousel-item ">
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningTwo} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningThree} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningFour} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningOne} alt="..." />
                            </div>
                          </Carousel.Item>
                          <Carousel.Item className="carousel-item ">
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningThree} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningFour} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningOne} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningTwo} alt="..." />
                            </div>
                          </Carousel.Item>
                          <Carousel.Item className="carousel-item">
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningFour} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningOne} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningTwo} alt="..." />
                            </div>
                            <div className="col-xs-3 col-sm-3 col-md-3">
                              <img src={planningThree} alt="..." />
                            </div>
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="alertDialog" style={{ width: "50%" }}>
              <Dialog open={loading}>
                <DialogContent>
                  <CircularProgress />
                </DialogContent>
              </Dialog>
              <Dialog open={openAlert}>
                <DialogTitle>Update Contact Information</DialogTitle>
                <DialogContent>
                  <div className="form-group py-2">
                    <label id="nameLabel" className="form-label">
                      First Name
                    </label>
                    <TextField
                      className="form-control"
                      value={firstName}
                      variant="outlined"
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={!!contactInfo?.Contact?.firstName}
                    />
                    {!setIsValidFirstName && (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        Invalid Firstname.
                      </span>
                    )}
                  </div>
                  <div className="form-group py-2">
                    <label id="nameLabel" className="form-label">
                      Last Name
                    </label>
                    <TextField
                      className="form-control"
                      value={lastName}
                      variant="outlined"
                      onChange={(e) => setLastName(e.target.value)}
                      disabled={!!contactInfo?.Contact?.lastName}
                    />
                    {!setIsValidLastName && (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        Invalid LastName.
                      </span>
                    )}
                  </div>
                  <div className="form-group py-2">
                    <label id="phoneLabel" className="form-label">
                      Phone Number
                    </label>
                    <TextField
                      className="form-control"
                      value={phone}
                      variant="outlined"
                      onChange={handlePhoneNumber}
                      disabled={!!contactInfo?.Contact?.phone1}
                    />
                    {!isValidPhoneNumber && (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        Phone number is 10 digits.
                      </span>
                    )}
                  </div>
                  <div className="form-group py-2">
                    <label id="emailLabel" className="form-label">
                      Email Address
                    </label>
                    <TextField
                      className="form-control"
                      value={email}
                      variant="outlined"
                      onChange={handleEmail}
                      disabled={!!contactInfo?.Contact?.emailAddress}
                    />
                    {!isValidEmailAddress && (
                      <span
                        id="validateEmailMessage"
                        style={{ fontWeight: "bold", color: "red" }}
                      >
                        Email is not valid.
                      </span>
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button id="submitAlertDialog" onClick={handleSubmit} color="primary" autoFocus>
                    Submit
                  </Button>
                  <Button id="closeAlertDialog" onClick={closeAlertDialog} color="primary" autoFocus>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </section>
        </div>
      </body>
    </>
  );
};

export default NavBarHomePage;
