import { getStatusFor } from "../../helper/HelperFunctions";
import OrderRelease from "../../types/OrderRelease";

export const getReportFileName = (fileType: string) => {
  return fileType + "_" + getDate() + ".csv";
};

export const getDate = () => {
  const date = new Date();
  return (
    date.toISOString().substr(0, 10) +
    "-" +
    date.getHours() +
    "-" +
    date.getMinutes()
  );
};

export function getOrderReleaseReportFixedHeaders() {
  return [
    { label: "Order Release ID", key: "orderReleaseID" },
    {
      label: "Order Release planning Status",
      key: "orderReleasePlanningStatus",
    },
    { label: "Early Product Ready", key: "earlyPickupDate" },
    { label: "Late Product Ready", key: "latePickupDate" },
    { label: "Ship From", key: "shipFrom" },
    { label: "Ship To", key: "shipTo" },
    { label: "Freight Terms", key: "freightTerms" },
    { label: "Shipment ID", key: "shipmentID" },
    { label: "SCAC", key: "scac" },
    { label: "Delivery Appointment ID", key: "appointmentID" },
    { label: "Vendor Pickup Number", key: "vendorPickupNumber" },
  ];
}

export function getOrderReleaseReportData(data: OrderRelease[]) {
  return data.map((orderRelease) => getOrderReleaseReportRowData(orderRelease));
}

export function getOrderReleaseReportRowData(orderRelease: OrderRelease) {
  const data = {
    orderReleaseID: orderRelease.orderReleaseGid.startsWith("FD.")
      ? orderRelease.orderReleaseGid.slice(3)
      : orderRelease.orderReleaseGid,
    orderReleasePlanningStatus:
      orderRelease.freightTerms === "FD.PREPAID"
        ? "PREPAID"
        : getStatusFor(orderRelease),
    earlyPickupDate: orderRelease.earlyPickupDate.split(" ")[0],
    latePickupDate: subtractOneDayFrom(orderRelease.latePickupDate)?.split(
      "T"
    )[0],
    shipFrom: orderRelease.shipFrom,
    shipTo: orderRelease.shipTo.split(".")[1],
    freightTerms: orderRelease.freightTerms.split(".")[1],
    shipmentID: orderRelease.shipmentId
      ? orderRelease.shipmentId
      : "No data to display",
    scac: orderRelease.scac
      ? orderRelease.scac.split(".")[1]
      : "No data to display",
    appointmentID: orderRelease.appointmentId
      ? orderRelease.appointmentId
      : "No data to display",
    vendorPickupNumber: orderRelease.vendorPickupNumber
      ? orderRelease.vendorPickupNumber
      : "No data to display",
  };
  return data;
}

export function subtractOneDayFrom(dateTime: string) {
  if (dateTime === null || dateTime === ""){
    return null;
  }
  let myDate = new Date(dateTime);
  myDate.setDate(myDate.getDate() - 1);
  return myDate.toISOString();
}
